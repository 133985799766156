import axios from 'axios'
import { refreshToken, getLoggedInUser } from '../auth/lock'
import { parseBlobResponse } from './utils'

export function handleResponseError(err) {
  const errorData = err?.response?.data
  const errorMessage = errorData?.msg
  if (err.response.status === 401) {
    // check if token expire
    if (errorMessage === 'jwt expired') {
      return refreshToken()
        .then(() =>
          // ones we refreshed token we need to add the new token to axios config and retry request
          axios.request({
            ...err.config,
            headers: {
              ...err.config.headers,
              Authorization: `Bearer ${getLoggedInUser().token}`
            }
          })
        )
        .catch(() => {
          // if refresh fails then user need's to login again
          window.location.assign('/auth/relogin')
        })
    }
    // if user try to do un authorized request like login when it is not registered
    // then we display an alert to notify user what happens otherwise it seems infinite login loop
    if (
      // eslint-disable-next-line no-alert
      !window.alert(
        'This account is not recognized. Please sign up or log in with a different account.'
      )
    ) {
      window.location.assign('/auth/relogin')
      return true
    }
  }
  if (errorData instanceof Blob) {
    return parseBlobResponse(errorData).then(res => {
      throw new Error(res.msg)
    })
  }
  throw new Error(errorMessage)
}

export default () => {
  axios.interceptors.response.use(response => response, handleResponseError)
}
