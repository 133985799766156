/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy } from 'react'

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import CVLoader from './components/CVLoader'

const EmployeeListPage = lazy(() => import('./containers/EmployeeListPage'))
const CVPage = lazy(() => import('./containers/CvPage'))
const Login = lazy(() => import('./containers/Login'))
const Callback = lazy(() => import('./containers/Callback'))
const NotFound = lazy(() => import('./components/NotFound'))
const DashboardPage = lazy(() => import('./containers/DashboardPage'))

const inDashboardPage = component => props => {
  const Component = component

  return (
    <DashboardPage {...props}>
      <Component {...props} />
    </DashboardPage>
  )
}

const routes = (
  <Suspense fallback={<CVLoader />}>
    <Switch>
      <Route exact path="/auth/relogin" render={props => <Login {...props} />} />
      <Route exact path="/" render={props => inDashboardPage(EmployeeListPage)(props)} />
      <Route exact path="/cv/:id/version/:index" render={props => inDashboardPage(CVPage)(props)} />
      <Route
        exact
        path="/cv/:id/version/:index/:versionId"
        render={props => inDashboardPage(CVPage)(props)}
      />
      <Route exact path="/callback" render={props => <Callback {...props} />} />
      <Route render={() => <NotFound />} />
    </Switch>
  </Suspense>
)

export default <Router>{routes}</Router>
