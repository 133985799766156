import Auth0Lock from 'auth0-lock'
import axios from 'axios'

import { getUserProfile } from '../utils/client'
import { loadData, saveData, deleteData } from '../persistence/local'

export const LOGIN = 'login'
export const REGISTER = 'register'
export const ACTION_LOCAL_STORAGE_KEY = 'sytac-action'

const LOCAL_STORAGE_KEY = 'sytac-resum'

const options = {
  theme: {},
  auth: {
    redirectUrl: `${window.location.origin}${process.env.AUTH0_REDIRECT_URI}`,
    audience: process.env.AUTH0_AUDIENCE_URI,
    responseType: 'token id_token',
    params: {
      scope: 'openid email roles'
    }
  },
  additionalSignUpFields: [
    {
      name: 'displayName',
      placeholder: 'Enter your full name'
    }
  ]
}

const lock = new Auth0Lock(process.env.AUTH0_CLIENT_ID, process.env.AUTH0_DOMAIN, options)

function setAuth0Action(action) {
  saveData(ACTION_LOCAL_STORAGE_KEY, action)
}

function getAuth0Action() {
  return loadData(ACTION_LOCAL_STORAGE_KEY)
}

function signInSubmit() {
  setAuth0Action(LOGIN)
}

function registerSubmit() {
  setAuth0Action(REGISTER)
}

function ssoSubmit(event) {
  setAuth0Action(event.action === 'signup' ? REGISTER : LOGIN)
}

lock.on('signin submit', signInSubmit)
lock.on('signup submit', registerSubmit)
lock.on('federated login', ssoSubmit)

const loadState = () => {
  const val = loadData(LOCAL_STORAGE_KEY)
  if (!val) {
    return {
      accessToken: null,
      idToken: null,
      expiresAt: null,
      user: null
    }
  }
  return val
}
const state = loadState()

const setSession = ({ accessToken, idToken, expiresIn }) => {
  const expiry = expiresIn * 1000 + Date.now()

  state.accessToken = accessToken
  state.idToken = idToken
  state.expiresAt = expiry
}

const getLoggedInUser = () => ({
  user: state.user,
  token: state.accessToken
})

const authenticate = () =>
  new Promise((resolve, reject) => {
    lock.on('authenticated', authResult => {
      setSession(authResult)
      lock.getUserInfo(authResult.accessToken, error => {
        if (error) {
          reject(error)
        }
        return axios.request(getUserProfile(state.accessToken, getAuth0Action())).then(response => {
          if (response.status === 200) {
            state.user = response.data
            saveData(LOCAL_STORAGE_KEY, state)
            resolve(state)
          }
        })
      })
    })
  })

const isAuthenticated = () => {
  const data = loadData(LOCAL_STORAGE_KEY)
  if (!data) return false

  return Date.now() < data.expiresAt
}

const signIn = theme => {
  lock.show({
    ...options,
    theme
  })
}

const signOut = () => {
  deleteData(LOCAL_STORAGE_KEY)
  lock.logout({
    returnTo: `${window.location.origin}/auth/relogin`,
    clientID: process.env.AUTH0_CLIENT_ID
  })
}

const refreshToken = () =>
  new Promise((resolve, reject) => {
    lock.checkSession({}, (err, authResult) => {
      if (err) {
        return reject(err)
      }
      setSession(authResult)
      return resolve()
    })
  })

export {
  signIn,
  isAuthenticated,
  authenticate,
  getLoggedInUser,
  signOut,
  refreshToken,
  signInSubmit,
  registerSubmit,
  ssoSubmit
}
