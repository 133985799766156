import React from 'react'
import { node } from 'prop-types'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'

import { Context as SettingsContext } from '../state/settings/context'

import styles from '../styles/theme-vars'

export const getTheme = settings =>
  createMuiTheme({
    ...settings,
    typography: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      useNextVariants: true,
      fontSize: 14
    },
    palette: {
      primary: {
        main: settings.brandOrange,
        contrastText: settings.white
      },
      secondary: {
        main: settings.gray2
      }
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*, html': {
            fontFamily: "'Montserrat', sans-serif"
          },
          'a, a:hover, a:visited': {
            color: 'inherit',
            textDecoration: 'none'
          }
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: settings.brandDarkBlue
        }
      },
      MuiPickersBasePicker: {
        pickerView: {
          width: '100%',
          maxWidth: 'unset'
        }
      },
      MuiPickersMonthSelection: {
        container: {
          margin: '0 auto'
        }
      },
      MuiPickersDay: {
        day: {
          color: settings.brandDarkBlue,
          '&:hover': {
            backgroundColor: settings.gray4
          }
        },
        current: {
          color: settings.white
        }
      },
      MuiInput: {
        underline: {
          color: settings.brandDarkBlue,
          '&:hover': {
            color: settings.brandDarkBlue
          },
          '&:before': {
            borderBottom: `1px solid ${settings.gray3}`
          },
          '&:after': {
            borderBottom: `1px solid ${settings.brandDarkBlue}`
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `1px solid ${settings.darkGray4}`
          },
          '&$focused': {
            color: settings.brandDarkBlue
          }
        }
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: settings.darkGray2
          }
        }
      },
      MuiIconButton: {
        root: {
          color: settings.darkGray3,
          '&:hover': {
            backgroundColor: 'transparent',
            color: settings.brandDarkBlue
          }
        }
      },
      MuiButton: {
        root: {
          borderRadius: '0px'
        },
        outlined: {
          padding: '0px 8px',
          lineHeight: '22px'
        }
      },
      MuiEdit: {
        root: {
          color: '#f00'
        }
      },
      MuiDialogTitle: {
        root: {
          '& h2': {
            fontSize: '16px'
          }
        }
      },
      MuiDialogActions: {
        root: {
          padding: ' 12px 24px',
          margin: '0px 8px'
        }
      },
      MuiDialogContent: {
        root: {
          minWidth: '400px',
          paddingTop: '24px'
        }
      }
    }
  })

const Theme = ({ children }) => {
  const [{ primaryColor }] = React.useContext(SettingsContext)
  return (
    <MuiThemeProvider
      theme={getTheme({
        ...styles,
        brandOrange: primaryColor
      })}>
      {children}
    </MuiThemeProvider>
  )
}

Theme.propTypes = {
  children: node.isRequired
}

export default Theme
