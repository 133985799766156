import React from 'react'
import { node } from 'prop-types'

import logo from '../../assets/sytac_logo_header.png'
import logoReverted from '../../assets/sytac-hexagon-long.png'

const defaultSettings = {
  name: 'Sytac',
  logo,
  logoReverted,
  phone: '+31 (0) 23 532 09 32',
  address: 'Rozenstraat 1, 2011 LS Haarlem',
  primaryColor: '#f57c00',
  email: 'info@sytac.io',
  facebookLink: '',
  twitterLink: '',
  mediumLink: '',
  linkedinLink: '',
  meetupLink: ''
}

const SettingsContext = React.createContext()

const { Consumer } = SettingsContext

function SettingsProvider({ children }) {
  const settings = React.useState(defaultSettings)
  return <SettingsContext.Provider value={settings}>{children}</SettingsContext.Provider>
}

SettingsProvider.propTypes = {
  children: node.isRequired
}

export { SettingsProvider as Provider, Consumer, SettingsContext as Context }
