export const getUserProfile = (token, action) => ({
  method: 'get',
  url: `${process.env.API_HOST}/api/userprofile/${action}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const createUser = (token, user) => ({
  method: 'post',
  url: `${process.env.API_HOST}/api/users`,
  data: {
    ...user
  },
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const getEmployees = (token, params) => ({
  method: 'get',
  url: `${process.env.API_HOST}/api/employees?${Object.entries(params)
    .map(kv => kv.map(encodeURIComponent).join('='))
    .join('&')}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const getEmployeeById = (token, id) => ({
  method: 'get',
  url: `${process.env.API_HOST}/api/employees/${id}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const saveEmployee = (token, employeeId, employee) => ({
  method: 'put',
  url: `${process.env.API_HOST}/api/employees/${employeeId}`,
  data: {
    employee
  },
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const cropProfilePicture = (token, employeeId, cropData) => ({
  method: 'post',
  url: `${process.env.API_HOST}/api/employees/${employeeId}/picturecrop`,
  data: {
    cropData
  },
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const deleteEmployee = (token, employeeId) => ({
  method: 'delete',
  url: `${process.env.API_HOST}/api/employees/${employeeId}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const toogleArchiveEmployee = (token, employeeId, archived) => ({
  method: 'put',
  url: `${process.env.API_HOST}/api/employees/${employeeId}/archive`,
  data: {
    archived
  },
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const getCv = (token, id) => ({
  method: 'get',
  url: `${process.env.API_HOST}/api/cv/${id}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const saveCv = (token, id, employee) => ({
  method: 'put',
  url: `${process.env.API_HOST}/api/cv/${id}`,
  data: {
    id,
    employee
  },
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const createVersion = (token, employeeId, version) => ({
  method: 'post',
  url: `${process.env.API_HOST}/api/version/${employeeId}`,
  data: {
    version
  },
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const updateVersion = (token, employeeId, versionId, version) => ({
  method: 'put',
  url: `${process.env.API_HOST}/api/version/${employeeId}/${versionId}`,
  data: {
    version
  },
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const deleteVersion = (token, employeeId, versionId) => ({
  method: 'delete',
  url: `${process.env.API_HOST}/api/version/${employeeId}/${versionId}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const downloadFile = (token, downloadUrl) => ({
  url: downloadUrl,
  method: 'GET',
  responseType: 'blob',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})

export const getSettings = () => ({
  method: 'get',
  url: `${process.env.API_HOST}/api/settings`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export const putSettings = (token, settings) => ({
  method: 'put',
  url: `${process.env.API_HOST}/api/settings`,
  data: settings,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
})
