import React from 'react'

import useSettingsEffects from '../state/settings/effects'

const Settings = ({ children }) => {
  const { loadSettings } = useSettingsEffects()

  React.useEffect(() => {
    loadSettings()
  }, [loadSettings])

  return children
}

export default Settings
