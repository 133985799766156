import React from 'react'
import axios from 'axios'

import { Context } from './context'
import { getSettings, putSettings } from '../../utils/client'

export default function useSettingsEffects() {
  // eslint-disable-next-line no-unused-vars
  const [_, setSettings] = React.useContext(Context)

  const loadSettings = React.useCallback(async () => {
    try {
      const response = await axios.request(getSettings())
      setSettings(response.data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }, [setSettings])

  const updateSettings = React.useCallback(
    async (token, settings) => {
      try {
        await axios.request(putSettings(token, settings))
        setSettings(settings)
        return settings
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return error
      }
    },
    [setSettings]
  )

  return {
    loadSettings,
    updateSettings
  }
}
