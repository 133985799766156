export default {
  brandDarkBlue: '#1a2531',
  brandOrange: '#f57c00',
  brandOrangeLighter: '#ffa141',
  brandFontColor: '#1a2531',
  brandSecondaryFontColor: '#aaa',
  brandBorderColor: '#ddd',
  brandDefaultButton: '#bbb',
  brandPrimaryButton: '#f57c00',
  brandDangerColor: '#ff0000',
  brandInfoColor: '#69d0f5',
  brandSuccessColor: '#0ac400',
  brandWarningColor: '#ebc063',
  darkGray1: '#333',
  darkGray2: '#555',
  darkGray3: '#777',
  darkGray4: '#999',
  gray1: '#aaa',
  gray2: '#bbb',
  gray3: '#ccc',
  gray4: '#ddd',
  gray5: '#f9f9f9',
  ghostWhite: '#f0f0f0',
  snowWhite: '#f9f9f9',
  white: '#ffffff',
  black: '#000000'
}
