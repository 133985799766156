/* eslint-disable no-param-reassign */
import { useImmerReducer } from '../sharedHooks/immer'

const SET_EMPLOYEE_LIST_DATA = 'employeeList/SET_EMPLOYEE_LIST_DATA'
const SET_FILTER_VALUE = 'employeeList/SET_FILTER_VALUE'
const SET_SEARCH_VALUE = 'employeeList/SET_SEARCH_VALUE'
const SET_SORT_BY = 'employeeList/SET_SORT_BY'
const SET_PAGINATION_PAGE = 'employeeList/SET_PAGINATION_PAGE'
const SET_PAGINATION_LIMIT = 'employeeList/SET_PAGINATION_LIMIT'

const SORT_KEY = 'employeeListSortOptions'
const FILTERS_KEY = 'employeeListFilters'
const SEARCH_KEY = 'employeeListSearch'

const DEFAULT_PAGINATION = {
  page: 0,
  limit: 25,
  count: 0
}

function useEmployeeListReducer() {
  const [employeeList, dispatch] = useImmerReducer(
    (state, action) => {
      switch (action.type) {
        case SET_EMPLOYEE_LIST_DATA:
          state.loading = false
          state.employees = action.employees
          state.count = action.count
          return state
        case SET_SEARCH_VALUE:
          state.searchValue = action.searchValue
          window.localStorage.setItem(SEARCH_KEY, action.searchValue)
          state.page = 0
          state.count = 0
          return state
        case SET_FILTER_VALUE:
          state.filters = [
            ...state.filters.filter(el => el.type && el.type !== action.filter.type),
            action.filter
          ]
          window.localStorage.setItem(FILTERS_KEY, JSON.stringify(state.filters))
          state.page = 0
          state.count = 0
          return state
        case SET_SORT_BY:
          state.sortBy = action.sortBy
          window.localStorage.setItem(SORT_KEY, action.sortBy)
          return state
        case SET_PAGINATION_PAGE:
          state.page = action.page
          return state
        case SET_PAGINATION_LIMIT:
          state.limit = action.limit
          state.page = 0
          return state
        default:
          return state
      }
    },
    {
      employees: [],
      employeesFiltered: [],
      searchValue: window.localStorage.getItem(SEARCH_KEY) || '',
      filters: JSON.parse(window.localStorage.getItem(FILTERS_KEY)) || [
        { type: 'archived', value: false, order: '' }
      ],
      sortBy: window.localStorage.getItem(SORT_KEY) || 'displayName_ASC',
      ...DEFAULT_PAGINATION
    }
  )

  function setEmployeeListData(employees, count) {
    return dispatch({
      type: SET_EMPLOYEE_LIST_DATA,
      employees,
      count
    })
  }
  function setSortBy(sortBy) {
    return dispatch({
      type: SET_SORT_BY,
      sortBy
    })
  }
  function setFilterValue(filterValue) {
    return dispatch({
      type: SET_FILTER_VALUE,
      filter: filterValue
    })
  }
  function setSearchValue(value) {
    return dispatch({
      type: SET_SEARCH_VALUE,
      searchValue: value
    })
  }

  function setPaginationPage(page) {
    return dispatch({
      type: SET_PAGINATION_PAGE,
      page
    })
  }
  function setPaginationLimit(limit) {
    return dispatch({
      type: SET_PAGINATION_LIMIT,
      limit
    })
  }
  return [
    employeeList,
    {
      setSortBy,
      setFilterValue,
      setSearchValue,
      setEmployeeListData,
      setPaginationPage,
      setPaginationLimit
    }
  ]
}

export default useEmployeeListReducer
