import React from 'react'
import produce from 'immer'

export function useImmer(initialValue) {
  const [val, updateValue] = React.useState(initialValue)
  return [
    val,
    updater => {
      updateValue(produce(updater))
    }
  ]
}

export function useImmerReducer(reducer, initialState, initialAction) {
  return React.useReducer(produce(reducer), initialState, initialAction)
}
