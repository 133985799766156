/* eslint-disable no-param-reassign */
import { useImmerReducer } from '../sharedHooks/immer'

const SET_CV_DATA = 'cv/SET_CV_DATA'
const SET_CV_PROFILE_PICTURE = 'cv/SET_CV_PROFILE_PICTURE'

function useCVReducer() {
  const [cvData, dispatch] = useImmerReducer(
    (state, { type, payload }) => {
      switch (type) {
        case SET_CV_DATA:
          state.loading = false
          state.cv = payload
          return state
        default:
          return state
      }
    },
    {
      cv: null
    }
  )

  const setCVData = payload => {
    return dispatch({
      type: SET_CV_DATA,
      payload
    })
  }

  const setProfilePicture = payload => {
    return dispatch({
      type: SET_CV_PROFILE_PICTURE,
      payload
    })
  }

  return [
    cvData,
    {
      setCVData,
      setProfilePicture
    }
  ]
}

export default useCVReducer
