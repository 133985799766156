import React from 'react'
import { node } from 'prop-types'
import useCVReducer from './reducer'

const CVContext = React.createContext()
const { Consumer } = CVContext

function CVProvider({ children }) {
  const cvData = useCVReducer()

  return <CVContext.Provider value={cvData}>{children}</CVContext.Provider>
}

CVProvider.propTypes = {
  children: node.isRequired
}

export { CVProvider as Provider, Consumer, CVContext as Context }
