import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  loadingBar: {
    position: 'absolute',
    left: '50%',
    marginLeft: '-15px',
    top: '100px',
    textAlign: 'center',
    padding: '30px'
  }
})

const CVLoader = () => {
  const classes = useStyles()
  return (
    <div className={classes.loadingBar}>
      <CircularProgress size={80} thickness={5} />
    </div>
  )
}

export default CVLoader
