import React from 'react'
import { node } from 'prop-types'

import useEmployeeListReducer from './reducer'

const EmployeeListContext = React.createContext()

const { Consumer } = EmployeeListContext

function EmployeeListProvider({ children }) {
  const employeeList = useEmployeeListReducer()
  return (
    <EmployeeListContext.Provider value={employeeList}>{children}</EmployeeListContext.Provider>
  )
}

EmployeeListProvider.propTypes = {
  children: node.isRequired
}

export { EmployeeListProvider as Provider, Consumer, EmployeeListContext as Context }
