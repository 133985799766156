const saveData = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data))
    return true
  } catch (e) {
    return false
  }
}

const loadData = key => {
  try {
    const val = localStorage.getItem(key)
    if (!val) {
      return undefined
    }
    return JSON.parse(val)
  } catch (e) {
    return undefined
  }
}

const deleteData = key => {
  try {
    return localStorage.removeItem(key)
  } catch (e) {
    return false
  }
}

export { saveData, loadData, deleteData }
